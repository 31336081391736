/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import img1 from "../../../../assets/images/bg2.jpg";
import img2 from "../../../../assets/images/macbook.png";

// const imagesPrefix = "../../../../assets/images";

export default [
  {
    image: img2,
    name: "Strategic Cosnulting Services",
    route: "#",
    description:
      "In our Strategic Consulting Services, we work closely with businesses to develop and execute organizational strategies aligned with their mission, vision, and long-term goals. From transformative leadership to change management and competitive analysis, we provide innovative solutions to drive sustainable growth.",
    btnData: "Book a Consultation",
  },
  {
    image: img1,
    name: "People and Talent Management Services",
    route: "#",
    description:
      "In our People and Talent Management Services, we focus on maximizing the potential of every individual and team. From recruitment assistance, workforce development to talent management and fostering inclusive & sustainable workplace cultures, we empower organizations to thrive through their people.",
    btnData: "Collaborate Now",
  },
  // {
  //   image: `${imagesPrefix}/bg3.jpg`,
  //   name: "Real Estate Works",
  //   route: "#",
  // },
  // {
  //   image: `${imagesPrefix}/team1.jpg`,
  //   name: "Government Projects",
  //   route: "#",
  // },
];
